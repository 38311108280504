<template>
<button 
  @click="$emit('click')"
  class="btn btn-overlay text-light rounded-3 mw-100"
  :class="{
    'btn-overlay-create': add,
    'btn-overlay-edit': edit
  }"
  >
  <span class="fa-stack">
    <i class="fas fa-circle fa-lg fa-stack-1x" style="color:white"></i>
    <i class="fas fa-pen    fa-xs fa-stack-1x"></i>
  </span>
  {{ add ? "Añadir" : '' }} {{ edit ? "Editar" : '' }} {{ media }}
</button>
</template>
  
<script>

export default {
  props: {
    add:  {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    media: String,
  },
};
</script>
  

<style scoped lang="scss">
@import "~@/assets/scss/abstracts/_variables.scss";

.btn-overlay {
  font-size: 0.8em;
  span.fa-stack {
    height: 0;
    bottom: 0.1em;
    right: 0.1em;
    width: 1.5em;
    .fa-circle {
      font-size: 1.5em;
      left: -14%;
    }
  }
  &-create {
    box-shadow: 0 0 7px rgba(0,0,0,0.3);
    background-color: $gray-2;
    .fa-pen {
      color: $gray-2;
      transition: color 0.3s ease; /* Smooth transition over 0.3 seconds */
    } 
    
  }
  
  &-edit {
    background-color: $blue;
    .fa-pen {
      color: $blue;
      transition: color 0.3s ease; /* Smooth transition over 0.3 seconds */
    }
  }
}
.btn-overlay:hover {
  background-color: $purple !important;
  .fa-pen {
    color: $purple !important;
    transition: color 0.3s ease; /* Smooth transition over 0.3 seconds */
  } 
}
</style>